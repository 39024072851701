import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

const Header = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const loggedIn = localStorage.getItem('isLoggedIn') === 'true';
    setIsLoggedIn(loggedIn);
  }, [location]);

  const handleLogout = () => {
    localStorage.removeItem('user');
    localStorage.removeItem('isLoggedIn');
    setIsLoggedIn(false);
    navigate('/');
  };

  const navItems = [
    { name: 'Home', path: '/' },
    { name: 'Explore', path: '/explore' },
    { name: 'For Sellers', path: '/for-sellers' },
    { name: 'About', path: '/about' },
  ];

  const isActive = (path) => {
    if (path === '/' && location.pathname !== '/') {
      return false;
    }
    return location.pathname.startsWith(path);
  };

  return (
    <header className="bg-white bg-opacity-90 backdrop-filter backdrop-blur-lg fixed w-full z-10 shadow-sm">
      <div className="container mx-auto px-4 py-4 flex justify-between items-center">
        <Link to="/" className="text-2xl md:text-3xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-indigo-500 to-blue-600">
          Stall.me
        </Link>
        <nav className="hidden md:block">
          <ul className="flex space-x-8">
            {navItems.map((item) => (
              <li key={item.name}>
                <Link
                  to={item.path}
                  className={`transition duration-300 text-sm uppercase tracking-wide ${
                    isActive(item.path)
                      ? 'text-blue-500 font-semibold'
                      : 'text-gray-600 hover:text-blue-500'
                  }`}
                >
                  {item.name}
                </Link>
              </li>
            ))}
          </ul>
        </nav>
        <div className="hidden md:flex items-center space-x-4">
          {isLoggedIn ? (
            <>
              <Link to="/seller-dashboard" className="text-blue-500 hover:text-blue-700 transition duration-300">Dashboard</Link>
              <button onClick={handleLogout} className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded transition duration-300">Logout</button>
            </>
          ) : (
            <>
              <Link to="/login" className="text-blue-500 hover:text-blue-700 transition duration-300">Login</Link>
              <Link to="/signup" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition duration-300">Sign Up</Link>
            </>
          )}
        </div>
        <button 
          className="md:hidden text-gray-600 hover:text-blue-500 transition duration-300"
          onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
        >
          {mobileMenuOpen ? 'Close' : 'Menu'}
        </button>
      </div>
      {mobileMenuOpen && (
        <div className="md:hidden">
          <ul className="px-4 pt-2 pb-4 space-y-2">
            {navItems.map((item) => (
              <li key={item.name}>
                <Link
                  to={item.path}
                  className={`block transition duration-300 ${
                    isActive(item.path)
                      ? 'text-blue-500 font-semibold'
                      : 'text-gray-600 hover:text-blue-500'
                  }`}
                  onClick={() => setMobileMenuOpen(false)}
                >
                  {item.name}
                </Link>
              </li>
            ))}
            {isLoggedIn ? (
              <>
                <li>
                  <Link to="/seller-dashboard" className="block text-blue-500 hover:text-blue-700 transition duration-300" onClick={() => setMobileMenuOpen(false)}>Dashboard</Link>
                </li>
                <li>
                  <button onClick={() => { handleLogout(); setMobileMenuOpen(false); }} className="w-full text-left text-red-500 hover:text-red-700 transition duration-300">Logout</button>
                </li>
              </>
            ) : (
              <>
                <li>
                  <Link to="/login" className="block text-blue-500 hover:text-blue-700 transition duration-300" onClick={() => setMobileMenuOpen(false)}>Login</Link>
                </li>
                <li>
                  <Link to="/signup" className="block text-blue-500 hover:text-blue-700 transition duration-300" onClick={() => setMobileMenuOpen(false)}>Sign Up</Link>
                </li>
              </>
            )}
          </ul>
        </div>
      )}
    </header>
  );
};

export default Header;