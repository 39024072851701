import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import SearchBar from '../components/SearchBar';
import GeolocationFilter from '../components/GeolocationFilter';
import SellerCard from '../components/SellerCard';
import { sellers } from '../data/sellers';

const Explore = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedLocation, setSelectedLocation] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Get the search term and location from URL parameters
    const params = new URLSearchParams(location.search);
    const searchParam = params.get('search');
    const locationParam = params.get('location');

    if (searchParam) setSearchTerm(searchParam);
    if (locationParam) setSelectedLocation(locationParam);

    // If no location in URL, try to get from local storage
    if (!locationParam) {
      const storedLocation = localStorage.getItem('selectedLocation');
      if (storedLocation) setSelectedLocation(storedLocation);
    }
  }, [location]);

  useEffect(() => {
    // Save selected location to local storage whenever it changes
    if (selectedLocation) {
      localStorage.setItem('selectedLocation', selectedLocation);
    }
  }, [selectedLocation]);

  const handleSearch = (term) => {
    setSearchTerm(term);
    updateURL(term, selectedLocation);
  };

  const handleLocationChange = (location) => {
    setSelectedLocation(location);
    updateURL(searchTerm, location);
  };

  const updateURL = (search, location) => {
    const params = new URLSearchParams();
    if (search) params.set('search', search);
    if (location) params.set('location', location);
    navigate(`/explore?${params.toString()}`);
  };

  const filteredSellers = sellers.filter(seller => 
    (seller.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
     seller.category.toLowerCase().includes(searchTerm.toLowerCase())) &&
    (!selectedLocation || seller.location === selectedLocation)
  );

  return (
    <div className="container mx-auto px-4 pt-24 md:pt-32 pb-16">
      <h1 className="text-3xl md:text-4xl font-bold text-center mb-8">Explore Sellers</h1>
      <div className="mb-12 flex flex-col md:flex-row justify-center items-center space-y-4 md:space-y-0 md:space-x-4">
        <SearchBar searchTerm={searchTerm} setSearchTerm={handleSearch} />
        <GeolocationFilter selectedLocation={selectedLocation} onLocationChange={handleLocationChange} />
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 md:gap-8">
        {filteredSellers.map((seller) => (
          <SellerCard key={seller.id} {...seller} />
        ))}
      </div>
    </div>
  );
};

export default Explore;