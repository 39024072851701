import React from 'react';
import { Link } from 'react-router-dom';
import { StarIcon } from '@heroicons/react/24/solid';

const SellerCard = ({ id, name, category, rating, reviews }) => {
  return (
    <Link to={`/stall/${id}`} className="block">
      <div className="bg-white rounded-xl shadow-lg overflow-hidden transform transition duration-500 hover:scale-105 hover:shadow-xl">
        <div className="h-40 md:h-48 bg-gradient-to-br from-indigo-200 to-blue-200"></div>
        <div className="p-4 md:p-6">
          <h3 className="font-semibold mb-2 text-lg">{name}</h3>
          <p className="text-sm text-gray-600 mb-3">{category}</p>
          <div className="flex items-center">
            <StarIcon className="text-yellow-400 h-5 w-5 mr-1" />
            <span className="text-sm font-medium">{rating} ({reviews} reviews)</span>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default SellerCard;