import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { XCircleIcon, PlusCircleIcon } from '@heroicons/react/24/solid';

const SellerDashboard = () => {
  const [user, setUser] = useState(null);
  const [storeDetails, setStoreDetails] = useState({
    name: '',
    category: '',
    description: '',
    customColor: '',
    customFont: '',
    website: '',
    phone: '',
    email: '',
    storeLinks: [],
    featuredListings: [],
    photos: []
  });
  const navigate = useNavigate();

  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem('user'));
    if (storedUser) {
      setUser(storedUser);
      // In a real application, you would fetch the store details from your backend here
      // For demo purposes, we'll use some dummy data
      setStoreDetails({
        name: storedUser.storeName,
        category: 'Electronics',
        description: 'We offer the latest gadgets and electronics at competitive prices.',
        customColor: '#4A90E2',
        customFont: 'Arial, sans-serif',
        website: 'https://www.example.com',
        phone: '+1 (555) 123-4567',
        email: storedUser.email,
        storeLinks: [
          { platform: 'Amazon', url: 'https://amazon.com/my-store' },
          { platform: 'eBay', url: 'https://ebay.com/my-store' }
        ],
        featuredListings: [
          { title: '4K Smart TV', price: '$499', url: 'https://example.com/4k-smart-tv' },
          { title: 'Wireless Earbuds', price: '$79', url: 'https://example.com/wireless-earbuds' }
        ],
        photos: [
          'https://placehold.co/400x300/4A90E2/FFFFFF.png?text=Store+Photo+1',
          'https://placehold.co/400x300/4A90E2/FFFFFF.png?text=Store+Photo+2'
        ]
      });
    } else {
      navigate('/login');
    }
  }, [navigate]);

  const handleLogout = () => {
    localStorage.removeItem('user');
    localStorage.removeItem('isLoggedIn');
    navigate('/login');
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setStoreDetails(prev => ({ ...prev, [name]: value }));
  };

  const handleStoreLinksChange = (index, field, value) => {
    const newStoreLinks = [...storeDetails.storeLinks];
    newStoreLinks[index][field] = value;
    setStoreDetails(prev => ({ ...prev, storeLinks: newStoreLinks }));
  };

  const handleFeaturedListingsChange = (index, field, value) => {
    const newFeaturedListings = [...storeDetails.featuredListings];
    newFeaturedListings[index][field] = value;
    setStoreDetails(prev => ({ ...prev, featuredListings: newFeaturedListings }));
  };

  const handleAddStoreLink = () => {
    setStoreDetails(prev => ({
      ...prev,
      storeLinks: [...prev.storeLinks, { platform: '', url: '' }]
    }));
  };

  const handleRemoveStoreLink = (index) => {
    setStoreDetails(prev => ({
      ...prev,
      storeLinks: prev.storeLinks.filter((_, i) => i !== index)
    }));
  };

  const handleAddFeaturedListing = () => {
    setStoreDetails(prev => ({
      ...prev,
      featuredListings: [...prev.featuredListings, { title: '', price: '', url: '' }]
    }));
  };

  const handleRemoveFeaturedListing = (index) => {
    setStoreDetails(prev => ({
      ...prev,
      featuredListings: prev.featuredListings.filter((_, i) => i !== index)
    }));
  };

  const handleAddPhoto = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setStoreDetails(prev => ({
          ...prev,
          photos: [...prev.photos, reader.result]
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleRemovePhoto = (index) => {
    setStoreDetails(prev => ({
      ...prev,
      photos: prev.photos.filter((_, i) => i !== index)
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Here you would typically send the updated store details to your backend
    console.log('Updated store details:', storeDetails);
    alert('Store details updated successfully!');
  };

  if (!user) return null;

  return (
    <div className="container mx-auto px-4 py-8 bg-gray-100 min-h-screen">
      <h1 className="text-4xl font-bold mb-8 text-center text-indigo-800">Welcome, {user.storeName}!</h1>
      <form onSubmit={handleSubmit} className="space-y-8">
        {/* Basic Information */}
        <div className="bg-white shadow-lg rounded-lg px-8 pt-6 pb-8">
          <h2 className="text-2xl font-semibold mb-6 text-indigo-700">Basic Information</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
                Store Name
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="name"
                type="text"
                name="name"
                value={storeDetails.name}
                onChange={handleInputChange}
              />
            </div>
            <div>
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="category">
                Category
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="category"
                type="text"
                name="category"
                value={storeDetails.category}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="mt-6">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="description">
              Description
            </label>
            <textarea
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="description"
              name="description"
              rows="4"
              value={storeDetails.description}
              onChange={handleInputChange}
            ></textarea>
          </div>
        </div>

        {/* Customization */}
        <div className="bg-white shadow-lg rounded-lg px-8 pt-6 pb-8">
          <h2 className="text-2xl font-semibold mb-6 text-indigo-700">Customization</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="customColor">
                Custom Color
              </label>
              <div className="flex items-center">
                <input
                  className="shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mr-2"
                  id="customColor"
                  type="color"
                  name="customColor"
                  value={storeDetails.customColor}
                  onChange={handleInputChange}
                />
                <span>{storeDetails.customColor}</span>
              </div>
            </div>
            <div>
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="customFont">
                Custom Font
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="customFont"
                type="text"
                name="customFont"
                value={storeDetails.customFont}
                onChange={handleInputChange}
              />
            </div>
          </div>
        </div>

        {/* Contact Information */}
        <div className="bg-white shadow-lg rounded-lg px-8 pt-6 pb-8">
          <h2 className="text-2xl font-semibold mb-6 text-indigo-700">Contact Information</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            <div>
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="website">
                Website
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="website"
                type="url"
                name="website"
                value={storeDetails.website}
                onChange={handleInputChange}
              />
            </div>
            <div>
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="phone">
                Phone
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="phone"
                type="tel"
                name="phone"
                value={storeDetails.phone}
                onChange={handleInputChange}
              />
            </div>
            <div>
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                Email
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="email"
                type="email"
                name="email"
                value={storeDetails.email}
                onChange={handleInputChange}
              />
            </div>
          </div>
        </div>

        {/* Store Links */}
        <div className="bg-white shadow-lg rounded-lg px-8 pt-6 pb-8">
          <h2 className="text-2xl font-semibold mb-6 text-indigo-700">Store Links</h2>
          {storeDetails.storeLinks.map((link, index) => (
            <div key={index} className="flex items-center space-x-4 mb-4">
              <div className="flex-grow grid grid-cols-1 md:grid-cols-2 gap-4">
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  type="text"
                  placeholder="Platform"
                  value={link.platform}
                  onChange={(e) => handleStoreLinksChange(index, 'platform', e.target.value)}
                />
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  type="url"
                  placeholder="URL"
                  value={link.url}
                  onChange={(e) => handleStoreLinksChange(index, 'url', e.target.value)}
                />
              </div>
              <button
                type="button"
                onClick={() => handleRemoveStoreLink(index)}
                className="text-red-500 hover:text-red-700"
              >
                <XCircleIcon className="h-6 w-6" />
              </button>
            </div>
          ))}
          <button
            type="button"
            onClick={handleAddStoreLink}
            className="mt-4 flex items-center text-indigo-600 hover:text-indigo-800"
          >
            <PlusCircleIcon className="h-5 w-5 mr-2" />
            Add Store Link
          </button>
        </div>

        {/* Featured Listings */}
        <div className="bg-white shadow-lg rounded-lg px-8 pt-6 pb-8">
          <h2 className="text-2xl font-semibold mb-6 text-indigo-700">Featured Listings</h2>
          {storeDetails.featuredListings.map((listing, index) => (
            <div key={index} className="flex items-center space-x-4 mb-4">
              <div className="flex-grow grid grid-cols-1 md:grid-cols-3 gap-4">
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  type="text"
                  placeholder="Title"
                  value={listing.title}
                  onChange={(e) => handleFeaturedListingsChange(index, 'title', e.target.value)}
                />
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  type="text"
                  placeholder="Price"
                  value={listing.price}
                  onChange={(e) => handleFeaturedListingsChange(index, 'price', e.target.value)}
                />
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  type="url"
                  placeholder="URL"
                  value={listing.url}
                  onChange={(e) => handleFeaturedListingsChange(index, 'url', e.target.value)}
                />
              </div>
              <button
                type="button"
                onClick={() => handleRemoveFeaturedListing(index)}
                className="text-red-500 hover:text-red-700"
              >
                <XCircleIcon className="h-6 w-6" />
              </button>
            </div>
          ))}
          <button
            type="button"
            onClick={handleAddFeaturedListing}
            className="mt-4 flex items-center text-indigo-600 hover:text-indigo-800"
          >
            <PlusCircleIcon className="h-5 w-5 mr-2" />
            Add Featured Listing
          </button>
        </div>

        {/* Photos */}
        <div className="bg-white shadow-lg rounded-lg px-8 pt-6 pb-8">
          <h2 className="text-2xl font-semibold mb-6 text-indigo-700">Photos</h2>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
            {storeDetails.photos.map((photo, index) => (
              <div key={index} className="relative group">
                <img src={photo} alt={`Store Photo ${index + 1}`} className="w-full h-32 object-cover rounded" />
                <button
                  type="button"
                  onClick={() => handleRemovePhoto(index)}
                  className="absolute top-0 right-0 bg-red-500 text-white rounded-full p-1 opacity-0 group-hover:opacity-100 transition-opacity"
                >
                  <XCircleIcon className="h-5 w-5" />
                </button>
              </div>
            ))}
          </div>
          <div className="mt-4">
            <label htmlFor="photo-upload" className="cursor-pointer flex items-center text-indigo-600 hover:text-indigo-800">
              <PlusCircleIcon className="h-5 w-5 mr-2" />
              Add Photo
            </label>
            <input
              id="photo-upload"
              type="file"
              accept="image/*"
              onChange={handleAddPhoto}
              className="hidden"
            />
          </div>
        </div>

        <div className="flex items-center justify-between">
          <button
            type="submit"
            className="bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition duration-300"
          >
            Save Changes
          </button>
          <button
            type="button"
            onClick={handleLogout}
            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition duration-300"
          >
            Logout
          </button>
        </div>
      </form>
      {user.username === 'demo' && (
        <p className="mt-8 text-center text-indigo-600 bg-indigo-100 border border-indigo-300 rounded-lg p-4">
          This is a demo account. Changes won't be permanently saved.
        </p>
      )}
    </div>
  );
};

export default SellerDashboard;