import React, { useState } from 'react';
import { StarIcon } from '@heroicons/react/24/solid';

const Reviews = ({ reviews = [] }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const reviewsPerPage = 5;

  if (reviews.length === 0) {
    return (
      <div className="mt-8">
        <h3 className="text-2xl font-semibold mb-4">Customer Reviews</h3>
        <p className="text-gray-600">No reviews available yet.</p>
      </div>
    );
  }

  const indexOfLastReview = currentPage * reviewsPerPage;
  const indexOfFirstReview = indexOfLastReview - reviewsPerPage;
  const currentReviews = reviews.slice(indexOfFirstReview, indexOfLastReview);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(reviews.length / reviewsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <div className="mt-8">
      <h3 className="text-2xl font-semibold mb-4">Customer Reviews</h3>
      {currentReviews.map((review, index) => (
        <div key={index} className="mb-4 p-4 bg-gray-50 rounded-lg">
          <div className="flex items-center mb-2">
            <StarIcon className="h-5 w-5 text-yellow-400 mr-1" />
            <span className="font-semibold">{review.rating}</span>
          </div>
          <p className="text-gray-700">{review.text}</p>
          <p className="text-sm text-gray-500 mt-2">- {review.author}</p>
        </div>
      ))}
      <div className="flex justify-center mt-4">
        {pageNumbers.map(number => (
          <button
            key={number}
            onClick={() => setCurrentPage(number)}
            className={`mx-1 px-3 py-1 rounded ${currentPage === number ? 'bg-indigo-500 text-white' : 'bg-gray-200'}`}
          >
            {number}
          </button>
        ))}
      </div>
    </div>
  );
};

export default Reviews;