export const sellers = [
    {
      id: 1,
      name: "Awesome Electronics",
      category: "Electronics",
      location: "USA",
      rating: 4.8,
      reviews: 120,
      description: "We offer the latest gadgets and electronics at competitive prices. From smartphones to smart home devices, we've got you covered with cutting-edge technology.",
      storeLinks: [
        { platform: "Amazon", url: "https://amazon.com/awesome-electronics" },
        { platform: "eBay", url: "https://ebay.com/awesome-electronics" }
      ],
      customColor: "#4A90E2",
      customFont: "Arial, sans-serif",
      website: "https://www.awesome-electronics.com",
      phone: "+1 (555) 123-4567",
      email: "contact@awesome-electronics.com",
      photos: [
        "https://placehold.co/400x300/4A90E2/FFFFFF.png?text=Electronics+1",
        "https://placehold.co/400x300/4A90E2/FFFFFF.png?text=Electronics+2",
        "https://placehold.co/400x300/4A90E2/FFFFFF.png?text=Electronics+3",
        "https://placehold.co/400x300/4A90E2/FFFFFF.png?text=Electronics+4"
      ],
      featuredListings: [
        { title: "4K Smart TV", price: "$499", url: "https://example.com/4k-smart-tv" },
        { title: "Wireless Earbuds", price: "$79", url: "https://example.com/wireless-earbuds" },
        { title: "Gaming Laptop", price: "$1299", url: "https://example.com/gaming-laptop" },
        { title: "Smartphone", price: "$699", url: "https://example.com/smartphone" }
      ],
      reviewsData: [
        { rating: 5, text: "Great products and fast shipping!", author: "John D." },
        { rating: 4, text: "Good selection, but prices are a bit high.", author: "Sarah M." },
        { rating: 5, text: "Excellent customer service!", author: "Mike R." },
        { rating: 4, text: "Quality products, would buy again.", author: "Emily L." },
        { rating: 5, text: "The smart TV I bought works perfectly.", author: "David W." }
      ]
    },
    {
      id: 2,
      name: "Fashionista Boutique",
      category: "Clothing & Accessories",
      location: "UK",
      rating: 4.6,
      reviews: 95,
      description: "Discover the latest fashion trends at Fashionista Boutique. We curate stylish clothing and accessories for the modern fashionista.",
      storeLinks: [
        { platform: "Etsy", url: "https://etsy.com/shop/fashionista-boutique" },
        { platform: "Shopify", url: "https://fashionista-boutique.myshopify.com" }
      ],
      customColor: "#FF6B6B",
      customFont: "Roboto, sans-serif",
      website: "https://www.fashionista-boutique.com",
      phone: "+1 (555) 987-6543",
      email: "info@fashionista-boutique.com",
      photos: [
        "https://placehold.co/400x300/FF6B6B/FFFFFF.png?text=Fashion+1",
        "https://placehold.co/400x300/FF6B6B/FFFFFF.png?text=Fashion+2",
        "https://placehold.co/400x300/FF6B6B/FFFFFF.png?text=Fashion+3",
        "https://placehold.co/400x300/FF6B6B/FFFFFF.png?text=Fashion+4"
      ],
      featuredListings: [
        { title: "Summer Dress", price: "$59", url: "https://example.com/summer-dress" },
        { title: "Designer Handbag", price: "$129", url: "https://example.com/designer-handbag" },
        { title: "Men's Suit", price: "$299", url: "https://example.com/mens-suit" },
        { title: "Jewelry Set", price: "$89", url: "https://example.com/jewelry-set" }
      ],
      reviewsData: [
        { rating: 5, text: "Love the trendy styles!", author: "Emma S." },
        { rating: 4, text: "Good quality clothes, but shipping was slow.", author: "Tom H." },
        { rating: 5, text: "The dress I bought fits perfectly!", author: "Sophie L." },
        { rating: 4, text: "Nice selection of accessories.", author: "Oliver P." },
        { rating: 5, text: "Great customer service experience.", author: "Anna M." }
      ]
    },
    {
      id: 3,
      name: "Green Thumb Garden Center",
      category: "Home & Garden",
      location: "Australia",
      rating: 4.7,
      reviews: 78,
      description: "Transform your living space with our wide selection of plants, gardening tools, and outdoor furniture. We're your one-stop-shop for all things green!",
      storeLinks: [
        { platform: "Wayfair", url: "https://wayfair.com/green-thumb-garden" },
        { platform: "Home Depot", url: "https://homedepot.com/green-thumb-garden" }
      ],
      customColor: "#66BB6A",
      customFont: "Georgia, serif",
      website: "https://www.greenthumbgarden.com",
      phone: "+1 (555) 456-7890",
      email: "grow@greenthumbgarden.com",
      photos: [
        "https://placehold.co/400x300/66BB6A/FFFFFF.png?text=Garden+1",
        "https://placehold.co/400x300/66BB6A/FFFFFF.png?text=Garden+2",
        "https://placehold.co/400x300/66BB6A/FFFFFF.png?text=Garden+3",
        "https://placehold.co/400x300/66BB6A/FFFFFF.png?text=Garden+4"
      ],
      featuredListings: [
        { title: "Indoor Plant Set", price: "$49", url: "https://example.com/indoor-plant-set" },
        { title: "Garden Tool Kit", price: "$79", url: "https://example.com/garden-tool-kit" },
        { title: "Patio Furniture Set", price: "$499", url: "https://example.com/patio-furniture" },
        { title: "Herb Garden Starter", price: "$29", url: "https://example.com/herb-garden-starter" }
      ],
      reviewsData: [
        { rating: 5, text: "Great selection of plants!", author: "Linda G." },
        { rating: 4, text: "The garden tools are high quality.", author: "Mark F." },
        { rating: 5, text: "Love my new patio furniture!", author: "Karen B." },
        { rating: 4, text: "Helpful staff, but limited parking.", author: "Paul N." },
        { rating: 5, text: "The herb garden starter kit is perfect for beginners.", author: "Rachel S." }
      ]
    },
    {
      id: 4,
      name: "Gourmet Delights",
      category: "Food & Beverages",
      location: "Canada",
      rating: 4.9,
      reviews: 150,
      description: "Indulge in exquisite gourmet foods and beverages from around the world. Our curated selection will tantalize your taste buds and elevate your culinary experiences.",
      storeLinks: [
        { platform: "Goldbelly", url: "https://goldbelly.com/gourmet-delights" },
        { platform: "Amazon", url: "https://amazon.com/gourmet-delights" }
      ],
      customColor: "#FFA000",
      customFont: "Merriweather, serif",
      website: "https://www.gourmet-delights.com",
      phone: "+1 (555) 789-0123",
      email: "taste@gourmet-delights.com",
      photos: [
        "https://placehold.co/400x300/FFA000/FFFFFF.png?text=Gourmet+1",
        "https://placehold.co/400x300/FFA000/FFFFFF.png?text=Gourmet+2",
        "https://placehold.co/400x300/FFA000/FFFFFF.png?text=Gourmet+3",
        "https://placehold.co/400x300/FFA000/FFFFFF.png?text=Gourmet+4"
      ],
      featuredListings: [
        { title: "Artisan Cheese Selection", price: "$79", url: "https://example.com/artisan-cheese" },
        { title: "Gourmet Coffee Sampler", price: "$45", url: "https://example.com/coffee-sampler" },
        { title: "Luxury Chocolate Box", price: "$59", url: "https://example.com/luxury-chocolate" },
        { title: "Exotic Spice Set", price: "$39", url: "https://example.com/exotic-spices" }
      ],
      reviewsData: [
        { rating: 5, text: "The artisan cheese selection is to die for!", author: "Sophia C." },
        { rating: 5, text: "Love the coffee sampler, great variety!", author: "James B." },
        { rating: 4, text: "Chocolate box was good, but a bit pricey.", author: "Olivia R." },
        { rating: 5, text: "The exotic spices have transformed my cooking!", author: "Ethan M." },
        { rating: 5, text: "Excellent gift options for food lovers.", author: "Isabella K." }
      ]
    },
    {
      id: 5,
      name: "Fitness Fanatics",
      category: "Sports & Fitness",
      location: "Germany",
      rating: 4.7,
      reviews: 110,
      description: "Achieve your fitness goals with our top-quality exercise equipment and accessories. From beginners to pros, we have everything you need to stay in shape.",
      storeLinks: [
        { platform: "Amazon", url: "https://amazon.com/fitness-fanatics" },
        { platform: "Dick's Sporting Goods", url: "https://dickssportinggoods.com/fitness-fanatics" }
      ],
      customColor: "#43A047",
      customFont: "Oswald, sans-serif",
      website: "https://www.fitness-fanatics.com",
      phone: "+1 (555) 234-5678",
      email: "info@fitness-fanatics.com",
      photos: [
        "https://placehold.co/400x300/43A047/FFFFFF.png?text=Fitness+1",
        "https://placehold.co/400x300/43A047/FFFFFF.png?text=Fitness+2",
        "https://placehold.co/400x300/43A047/FFFFFF.png?text=Fitness+3",
        "https://placehold.co/400x300/43A047/FFFFFF.png?text=Fitness+4"
      ],
      featuredListings: [
        { title: "Smart Fitness Tracker", price: "$89", url: "https://example.com/fitness-tracker" },
        { title: "Adjustable Dumbbell Set", price: "$249", url: "https://example.com/dumbbell-set" },
        { title: "Yoga Mat Kit", price: "$49", url: "https://example.com/yoga-mat-kit" },
        { title: "Resistance Bands Pack", price: "$29", url: "https://example.com/resistance-bands" }
      ],
      reviewsData: [
        { rating: 5, text: "The fitness tracker is accurate and motivating!", author: "Chris L." },
        { rating: 4, text: "Good quality dumbbells, but delivery was delayed.", author: "Amanda W." },
        { rating: 5, text: "Love the yoga mat kit, great for beginners.", author: "Michael S." },
        { rating: 5, text: "Resistance bands are versatile and durable.", author: "Jessica T." },
        { rating: 4, text: "Decent prices for quality fitness gear.", author: "Daniel R." }
      ]
    },
    {
      id: 6,
      name: "Bookworm's Haven",
      category: "Books & Media",
      location: "Japan",
      rating: 4.8,
      reviews: 200,
      description: "Dive into a world of stories, knowledge, and imagination. Our vast collection of books caters to all ages and interests, from classic literature to contemporary bestsellers.",
      storeLinks: [
        { platform: "Amazon", url: "https://amazon.com/bookworms-haven" },
        { platform: "Barnes & Noble", url: "https://barnesandnoble.com/bookworms-haven" }
      ],
      customColor: "#795548",
      customFont: "Lora, serif",
      website: "https://www.bookwormshaven.com",
      phone: "+1 (555) 876-5432",
      email: "read@bookwormshaven.com",
      photos: [
        "https://placehold.co/400x300/795548/FFFFFF.png?text=Books+1",
        "https://placehold.co/400x300/795548/FFFFFF.png?text=Books+2",
        "https://placehold.co/400x300/795548/FFFFFF.png?text=Books+3",
        "https://placehold.co/400x300/795548/FFFFFF.png?text=Books+4"
      ],
      featuredListings: [
        { title: "Bestseller Book Bundle", price: "$59", url: "https://example.com/bestseller-bundle" },
        { title: "Children's Illustrated Series", price: "$39", url: "https://example.com/children-series" },
        { title: "Science Fiction Collection", price: "$49", url: "https://example.com/scifi-collection" },
        { title: "Cookbook Set", price: "$79", url: "https://example.com/cookbook-set" }
      ],
      reviewsData: [
        { rating: 5, text: "Amazing selection of books for all ages!", author: "Laura H." },
        { rating: 5, text: "The children's series is beautifully illustrated.", author: "Robert M." },
        { rating: 4, text: "Good sci-fi collection, wish it had more recent titles.", author: "Alex K." },
        { rating: 5, text: "Love the cookbook set, great for gift-giving.", author: "Natalie P." },
        { rating: 5, text: "Prompt delivery and books were in perfect condition.", author: "William J." }
      ]
    }
  ];