import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="bg-gray-800 text-white py-8">
      <div className="container mx-auto px-4 flex flex-col md:flex-row justify-between items-center">
        <p className="mb-4 md:mb-0">&copy; 2024 Stall.me. All rights reserved.<br />Created by <a href="https://lance.name" target="_blank" className="hover:text-blue-400 transition duration-300">Lance</a></p>
        <div className="flex space-x-6">
          <Link to="/terms" className="hover:text-blue-400 transition duration-300">Terms</Link>
          <Link to="/privacy" className="hover:text-blue-400 transition duration-300">Privacy</Link>
          <Link to="/contact" className="hover:text-blue-400 transition duration-300">Contact</Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;