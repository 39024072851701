import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import SearchBar from '../components/SearchBar';
import FeatureCard from '../components/FeatureCard';
import SellerCard from '../components/SellerCard';
import GeolocationFilter from '../components/GeolocationFilter';
import { StarIcon, BriefcaseIcon, ShoppingCartIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import { sellers } from '../data/sellers';

const Home = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedLocation, setSelectedLocation] = useState('');
  const navigate = useNavigate();

  const features = [
    { Icon: StarIcon, title: "Trusted Sellers", desc: "Discover top-rated sellers from various platforms, all in one place.", color: "text-yellow-400" },
    { Icon: BriefcaseIcon, title: "Diverse Categories", desc: "Explore a wide range of product categories from multiple marketplaces.", color: "text-blue-500" },
    { Icon: ShoppingCartIcon, title: "Easy Shopping", desc: "Seamlessly navigate to your favorite stores across different platforms.", color: "text-green-500" }
  ];

  const filteredSellers = sellers
    .filter(seller => !selectedLocation || seller.location === selectedLocation)
    .slice(0, 4);

  const handleSearch = (term) => {
    navigate(`/explore?search=${encodeURIComponent(term)}&location=${encodeURIComponent(selectedLocation)}`);
  };

  const handleLocationChange = (location) => {
    setSelectedLocation(location);
  };

  return (
    <div className="container mx-auto px-4 pt-24 md:pt-32 pb-16">
      <section className="text-center mb-16 md:mb-24">
        <h2 className="text-4xl md:text-5xl font-bold text-gray-800 mb-6 leading-tight">
          Your <span className="text-transparent bg-clip-text bg-gradient-to-r from-indigo-500 to-blue-600">Universal</span> Storefront
        </h2>
        <p className="text-lg md:text-xl text-gray-600 mb-8 md:mb-12 max-w-2xl mx-auto leading-relaxed">
          Connect all your online stores in one beautiful, customizable page. Boost your visibility and sales across platforms.
        </p>
        <div className="flex flex-col md:flex-row justify-center items-center space-y-4 md:space-y-0 md:space-x-4 mb-8">
          <SearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm} onSearch={handleSearch} />
          <GeolocationFilter selectedLocation={selectedLocation} onLocationChange={handleLocationChange} />
        </div>
      </section>

      <section className="grid grid-cols-1 md:grid-cols-3 gap-6 md:gap-10 mb-16 md:mb-24">
        {features.map((feature, index) => (
          <FeatureCard key={index} {...feature} />
        ))}
      </section>

      <section className="text-center bg-gradient-to-r from-indigo-500 to-blue-600 text-white py-12 md:py-20 rounded-3xl shadow-2xl mb-16 md:mb-24">
        <h2 className="text-3xl md:text-4xl font-bold mb-4 md:mb-6">Join Stall.me Today</h2>
        <p className="text-lg md:text-xl mb-8 md:mb-10 max-w-2xl mx-auto leading-relaxed px-4">
          Create your universal storefront and reach more customers across all your selling platforms.
        </p>
        <Link to="/signup" className="bg-white text-indigo-600 px-8 py-4 rounded-full text-lg font-semibold hover:opacity-90 transition duration-300 shadow-lg inline-flex items-center">
            Get Started
            <ChevronRightIcon className="w-5 h-5 ml-2" />
        </Link>
      </section>

      <section className="mb-16 md:mb-24">
        <h2 className="text-2xl md:text-3xl font-bold text-center mb-8 md:mb-12">
          {selectedLocation ? `Featured Sellers in ${selectedLocation}` : 'Featured Sellers'}
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-6 md:gap-8">
          {filteredSellers.map((seller) => (
            <SellerCard key={seller.id} {...seller} />
          ))}
        </div>
      </section>
    </div>
  );
};

export default Home;