import React from 'react';
import { GlobeAltIcon, LightBulbIcon, UserGroupIcon, EnvelopeIcon } from '@heroicons/react/24/outline';

const About = () => {
  const features = [
    { icon: GlobeAltIcon, title: "Unified Platform", description: "Connect multiple e-commerce platforms in one place" },
    { icon: LightBulbIcon, title: "Innovative Solutions", description: "Cutting-edge tools for sellers and buyers" },
    { icon: UserGroupIcon, title: "Community Focused", description: "Building a thriving e-commerce ecosystem" },
  ];

  return (
    <div className="bg-gradient-to-b from-indigo-50 to-white">
      <div className="container mx-auto px-4 pt-24 md:pt-32 pb-16">
        <h1 className="text-4xl md:text-5xl font-bold text-center mb-8 text-indigo-800">About Stall.me</h1>
        
        <div className="max-w-3xl mx-auto text-center mb-12">
          <p className="text-xl text-gray-700 mb-6">
            Stall.me is a revolutionary platform designed to bridge the gap between sellers and buyers across multiple e-commerce platforms. 
            Our mission is to simplify the online shopping experience by providing a unified storefront for sellers and a one-stop-shop for buyers.
          </p>
        </div>

        <div className="grid md:grid-cols-3 gap-8 mb-16">
          {features.map((feature, index) => (
            <div key={index} className="bg-white p-6 rounded-lg shadow-md text-center">
              <feature.icon className="w-12 h-12 text-indigo-500 mx-auto mb-4" />
              <h3 className="text-xl font-semibold mb-2">{feature.title}</h3>
              <p className="text-gray-600">{feature.description}</p>
            </div>
          ))}
        </div>

        <div className="max-w-3xl mx-auto mb-16">
          <h2 className="text-3xl font-semibold mb-6 text-center text-indigo-800">Our Vision</h2>
          <p className="text-lg text-gray-700 mb-6">
            We envision a world where e-commerce is seamless, efficient, and accessible to everyone. 
            By connecting various marketplaces and providing tools for sellers to showcase their products, 
            we're creating an ecosystem that benefits both sellers and buyers alike.
          </p>
          <div className="bg-indigo-100 border-l-4 border-indigo-500 p-4 rounded">
            <p className="text-indigo-700 font-semibold">
              "Our goal is to empower sellers and delight buyers by creating the most innovative and user-friendly e-commerce platform."
            </p>
          </div>
        </div>

        <div className="max-w-3xl mx-auto mb-16">
          <h2 className="text-3xl font-semibold mb-6 text-center text-indigo-800">Our Team</h2>
          <p className="text-lg text-gray-700 mb-6">
            Stall.me is built by a passionate team of e-commerce experts, software engineers, and designers. 
            We're dedicated to creating the best possible experience for our users and constantly innovating to meet the evolving needs of the online marketplace.
          </p>
        </div>

        <div className="max-w-3xl mx-auto">
          <h2 className="text-3xl font-semibold mb-6 text-center text-indigo-800">Contact Us</h2>
          <div className="bg-white p-6 rounded-lg shadow-md">
            <p className="text-lg text-gray-700 mb-4">
              Have questions or suggestions? We'd love to hear from you! Reach out to us using any of the methods below:
            </p>
            <div className="flex items-center mb-2">
              <EnvelopeIcon className="w-6 h-6 text-indigo-500 mr-2" />
              <a href="mailto:contact@stall.me" className="text-blue-600 hover:underline">contact@stall.me</a>
            </div>
            <p className="text-gray-600 mt-4">
              We typically respond to inquiries within 24-48 hours. Your feedback helps us improve Stall.me for everyone!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;