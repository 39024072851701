import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const LoginForm = () => {
  const [formData, setFormData] = useState({
    username: '',
    password: '',
  });
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    loginUser(formData.username, formData.password);
  };

  const loginUser = (username, password) => {
    // Check for demo login
    if (username === 'demo' && password === 'password') {
      const demoUser = {
        username: 'demo',
        email: 'demo@example.com',
        storeName: 'Demo Store'
      };
      localStorage.setItem('user', JSON.stringify(demoUser));
      localStorage.setItem('isLoggedIn', 'true');
      navigate('/seller-dashboard');
      return;
    }

    // Regular login logic (for when you implement actual authentication)
    const storedUser = JSON.parse(localStorage.getItem('user'));
    if (storedUser && storedUser.username === username && storedUser.password === password) {
      localStorage.setItem('isLoggedIn', 'true');
      navigate('/seller-dashboard');
    } else {
      alert('Invalid credentials');
    }
  };

  const handleDemoLogin = () => {
    loginUser('demo', 'password');
  };

  return (
    <div className="max-w-md mx-auto mt-10">
      <form onSubmit={handleSubmit} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <h2 className="text-2xl mb-6 text-center font-bold">Login</h2>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="username">
            Username
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="username"
            type="text"
            placeholder="Username"
            name="username"
            value={formData.username}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-6">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
            Password
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
            id="password"
            type="password"
            placeholder="******************"
            name="password"
            value={formData.password}
            onChange={handleChange}
            required
          />
        </div>
        <div className="flex items-center justify-between">
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            type="submit"
          >
            Sign In
          </button>
          <button
            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            type="button"
            onClick={handleDemoLogin}
          >
            Demo Login
          </button>
        </div>
      </form>
      <div className="text-center mt-4">
        <p className="text-gray-600">Demo Credentials:</p>
        <p className="text-gray-800">Username: demo</p>
        <p className="text-gray-800">Password: password</p>
      </div>
    </div>
  );
};

export default LoginForm;