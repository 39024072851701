import React from 'react';
import { Link } from 'react-router-dom';
import { ChevronRightIcon, CheckCircleIcon, ChartBarIcon, GlobeAltIcon, CurrencyDollarIcon, UserGroupIcon } from '@heroicons/react/24/outline';

const ForSellers = () => {
  const benefits = [
    { icon: GlobeAltIcon, text: "Centralize your online presence across multiple platforms" },
    { icon: ChartBarIcon, text: "Increase visibility and reach more potential customers" },
    { icon: UserGroupIcon, text: "Attract more buyers with a unified, professional storefront" },
    { icon: CurrencyDollarIcon, text: "Boost sales with integrated analytics and insights" },
  ];

  const testimonials = [
    { name: "Sarah Johnson", role: "Vintage Clothing Seller", quote: "Stall.me has transformed my online business. I've seen a 40% increase in sales since joining!" },
    { name: "Mike Chen", role: "Electronics Retailer", quote: "The unified dashboard saves me hours each week. I can manage all my listings in one place." },
  ];

  return (
    <div className="bg-gradient-to-b from-indigo-50 to-white">
      <div className="container mx-auto px-4 pt-24 md:pt-32 pb-16">
        <h1 className="text-4xl md:text-5xl font-bold text-center mb-8 text-indigo-800">Grow Your Business with Stall.me</h1>
        
        <div className="max-w-4xl mx-auto text-center mb-12">
          <p className="text-xl text-gray-700 mb-8">
            Join Stall.me and showcase your products across multiple platforms in one centralized location. 
            Increase your visibility, reach more customers, and streamline your online selling experience.
          </p>
          <Link to="/signup" className="bg-gradient-to-r from-indigo-500 to-blue-600 text-white px-8 py-4 rounded-full text-lg font-semibold hover:opacity-90 transition duration-300 shadow-lg inline-flex items-center">
            Get Started as a Seller
            <ChevronRightIcon className="w-5 h-5 ml-2" />
          </Link>
        </div>

        <div className="grid md:grid-cols-2 gap-8 mb-16">
          {benefits.map((benefit, index) => (
            <div key={index} className="bg-white p-6 rounded-lg shadow-md flex items-start">
              <benefit.icon className="w-8 h-8 text-indigo-500 mr-4 flex-shrink-0" />
              <p className="text-lg text-gray-700">{benefit.text}</p>
            </div>
          ))}
        </div>

        <div className="bg-indigo-700 text-white rounded-lg p-8 mb-16">
          <h2 className="text-3xl font-semibold mb-6 text-center">Why Sellers Love Stall.me</h2>
          <div className="grid md:grid-cols-2 gap-8">
            {testimonials.map((testimonial, index) => (
              <div key={index} className="bg-indigo-600 p-6 rounded-lg">
                <p className="text-lg mb-4">"{testimonial.quote}"</p>
                <p className="font-semibold">{testimonial.name}</p>
                <p className="text-indigo-200">{testimonial.role}</p>
              </div>
            ))}
          </div>
        </div>

        <div className="max-w-3xl mx-auto">
          <h2 className="text-2xl font-semibold mb-6 text-center text-indigo-800">Getting Started is Easy</h2>
          <ul className="space-y-4">
            {[
              "Sign up for a Stall.me account",
              "Connect your existing online store platforms",
              "Customize your unified storefront",
              "Start reaching more customers and growing your sales"
            ].map((step, index) => (
              <li key={index} className="flex items-center">
                <CheckCircleIcon className="w-6 h-6 text-green-500 mr-2" />
                <span className="text-lg text-gray-700">{step}</span>
              </li>
            ))}
          </ul>
        </div>

        <div className="text-center mt-12">
          <Link to="/signup" className="bg-gradient-to-r from-indigo-500 to-blue-600 text-white px-8 py-4 rounded-full text-lg font-semibold hover:opacity-90 transition duration-300 shadow-lg inline-flex items-center">
            Join Stall.me Today
            <ChevronRightIcon className="w-5 h-5 ml-2" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ForSellers;