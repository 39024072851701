import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { sellers } from '../data/sellers';
import Reviews from '../components/Reviews';
import { StarIcon, ShoppingBagIcon, ArrowLeftIcon, GlobeAltIcon, PhoneIcon, EnvelopeIcon, PhotographIcon, TagIcon } from '@heroicons/react/24/solid';

const StallPage = () => {
    const { id } = useParams();
    const seller = sellers.find(s => s.id === parseInt(id));

    if (!seller) {
        return (
            <div className="container mx-auto px-4 py-8 mt-20 text-center">
                <h1 className="text-3xl font-bold mb-4">Seller not found</h1>
                <Link to="/explore" className="text-blue-500 hover:underline">Back to Explore</Link>
            </div>
        );
    }

    return (
        <div className="pt-20" style={{
            fontFamily: seller.customFont,
            '--custom-color': seller.customColor
        }}>
            <div className="bg-gradient-to-r from-indigo-500 to-blue-600 text-white py-12">
                <div className="container mx-auto px-4">
                    <Link to="/explore" className="inline-flex items-center text-white hover:underline mb-4">
                        <ArrowLeftIcon className="h-5 w-5 mr-2" />
                        Back to Explore
                    </Link>
                    <h1 className="text-4xl md:text-5xl font-bold mb-2">{seller.name}</h1>
                    <p className="text-xl mb-4">{seller.category} • {seller.location}</p>
                    <div className="flex items-center">
                        <StarIcon className="h-6 w-6 text-yellow-400 mr-1" />
                        <span className="text-lg">{seller.rating} ({seller.reviews} reviews)</span>
                    </div>
                </div>
            </div>

            <div className="container mx-auto px-4 py-8">
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                    <div className="md:col-span-2">
                        <div className="bg-white rounded-lg shadow-lg p-6 mb-8">
                            <h2 className="text-2xl font-semibold mb-4">About Us</h2>
                            <p className="text-gray-700">{seller.description}</p>
                        </div>

                        <div className="bg-white rounded-lg shadow-lg p-6 mb-8">
                            <h2 className="text-2xl font-semibold mb-4">Our Stores</h2>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                {seller.storeLinks.map((link, index) => (
                                    <a
                                        key={index}
                                        href={link.url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="flex items-center p-4 border border-gray-200 rounded hover:shadow-md transition duration-300"
                                        style={{ borderColor: 'var(--custom-color)' }}
                                    >
                                        <ShoppingBagIcon className="h-6 w-6 mr-3" style={{ color: 'var(--custom-color)' }} />
                                        <div>
                                            <span className="font-semibold block">{link.platform}</span>
                                            <span className="text-sm text-gray-500">Visit Store</span>
                                        </div>
                                    </a>
                                ))}
                            </div>
                        </div>

                        <div className="bg-white rounded-lg shadow-lg p-6 mb-8">
                            <h2 className="text-2xl font-semibold mb-4">Featured Listings</h2>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                {seller.featuredListings && seller.featuredListings.map((listing, index) => (
                                    <a
                                        key={index}
                                        href={listing.url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="flex items-center p-4 border border-gray-200 rounded hover:shadow-md transition duration-300"
                                        style={{ borderColor: 'var(--custom-color)' }}
                                    >
                                        <TagIcon className="h-6 w-6 mr-3" style={{ color: 'var(--custom-color)' }} />
                                        <div>
                                            <span className="font-semibold block">{listing.title}</span>
                                            <span className="text-sm text-gray-500">{listing.price}</span>
                                        </div>
                                    </a>
                                ))}
                            </div>
                        </div>

                        <Reviews reviews={seller.reviewsData || []} />
                    </div>

                    <div>
                        <div className="bg-white rounded-lg shadow-lg p-6 mb-8">
                            <h2 className="text-2xl font-semibold mb-4">Contact Information</h2>
                            {seller.website && (
                                <div className="flex items-center mb-3">
                                    <GlobeAltIcon className="h-5 w-5 mr-2" style={{ color: 'var(--custom-color)' }} />
                                    <a href={seller.website} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">{seller.website}</a>
                                </div>
                            )}
                            {seller.phone && (
                                <div className="flex items-center mb-3">
                                    <PhoneIcon className="h-5 w-5 mr-2" style={{ color: 'var(--custom-color)' }} />
                                    <a href={`tel:${seller.phone}`} className="text-blue-500 hover:underline">{seller.phone}</a>
                                </div>
                            )}
                            {seller.email && (
                                <div className="flex items-center">
                                    <EnvelopeIcon className="h-5 w-5 mr-2" style={{ color: 'var(--custom-color)' }} />
                                    <a href={`mailto:${seller.email}`} className="text-blue-500 hover:underline">{seller.email}</a>
                                </div>
                            )}
                        </div>

                        {seller.photos && seller.photos.length > 0 && (
                            <div className="bg-white rounded-lg shadow-lg p-6 mb-8">
                                <h2 className="text-2xl font-semibold mb-4">Photos</h2>
                                <div className="grid grid-cols-2 gap-4">
                                    {seller.photos.map((photo, index) => (
                                        <img key={index} src={photo} alt={`${seller.name} - Photo ${index + 1}`} className="w-full h-32 object-cover rounded" />
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StallPage;