import React from 'react';

const FeatureCard = ({ Icon, title, desc, color }) => {
  return (
    <div className="bg-white bg-opacity-80 backdrop-filter backdrop-blur-lg p-6 md:p-8 rounded-2xl shadow-xl transform transition duration-500 hover:scale-105 hover:shadow-2xl">
      {Icon && <Icon className={`mx-auto mb-4 md:mb-6 h-12 w-12 ${color}`} />}
      <h3 className="text-xl md:text-2xl font-semibold mb-3 md:mb-4">{title}</h3>
      <p className="text-gray-600 leading-relaxed text-sm md:text-base">{desc}</p>
    </div>
  );
};

export default FeatureCard;