import React from 'react';
import { ClipboardDocumentListIcon, ShieldCheckIcon, ScaleIcon } from '@heroicons/react/24/outline';

const Terms = () => {
  const sections = [
    {
      icon: ClipboardDocumentListIcon,
      title: "1. Acceptance of Terms",
      content: "By accessing and using Stall.me, you agree to be bound by these Terms of Service and all applicable laws and regulations. If you do not agree with any part of these terms, you may not use our services."
    },
    {
      icon: ShieldCheckIcon,
      title: "2. User Responsibilities",
      content: "You are responsible for maintaining the confidentiality of your account and password. You agree to accept responsibility for all activities that occur under your account or password."
    },
    {
      icon: ScaleIcon,
      title: "3. Intellectual Property",
      content: "The content, organization, graphics, design, compilation, magnetic translation, digital conversion and other matters related to the Site are protected under applicable copyrights, trademarks and other proprietary rights."
    },
  ];

  return (
    <div className="bg-gradient-to-b from-indigo-50 to-white min-h-screen">
      <div className="container mx-auto px-4 pt-24 md:pt-32 pb-16">
        <h1 className="text-4xl md:text-5xl font-bold text-center mb-8 text-indigo-800">Terms of Service</h1>
        
        <div className="max-w-3xl mx-auto">
          <p className="text-lg text-gray-700 mb-8">
            Welcome to Stall.me. These Terms of Service govern your use of our website and services. Please read them carefully before using our platform.
          </p>

          {sections.map((section, index) => (
            <div key={index} className="mb-8">
              <div className="flex items-center mb-4">
                <section.icon className="w-8 h-8 text-indigo-600 mr-4" />
                <h2 className="text-2xl font-semibold text-indigo-800">{section.title}</h2>
              </div>
              <p className="text-gray-700 ml-12">{section.content}</p>
            </div>
          ))}

          <p className="text-gray-700 mt-8">
            For the full Terms of Service, please contact us at{' '}
            <a href="mailto:legal@stall.me" className="text-blue-600 hover:underline">legal@stall.me</a>.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Terms;