import React, { useState, useEffect } from 'react';
import { GlobeAltIcon } from '@heroicons/react/24/solid';

const GeolocationFilter = ({ selectedLocation, onLocationChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const locations = ['Australia', 'New Zealand', 'USA', 'UK', 'Canada', 'Germany', 'Japan'];

  useEffect(() => {
    if (!selectedLocation) {
      fetchLocation();
    }
  }, [selectedLocation]);

  const fetchLocation = async () => {
    setIsLoading(true);
    try {
      const response = await fetch('https://stall.me/local_geolocation.php');
      const data = await response.json();
      if (data.location && locations.includes(data.location)) {
        onLocationChange(data.location);
      } else {
        // If the location is not in our list, default to the first location
        onLocationChange(locations[0]);
      }
    } catch (error) {
      console.error('Error fetching location:', error);
      onLocationChange(locations[0]);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="relative">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center space-x-2 bg-white border border-gray-300 rounded-full px-4 py-2 text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
        disabled={isLoading}
      >
        <GlobeAltIcon className="h-5 w-5 text-gray-400" />
        <span>{isLoading ? 'Detecting location...' : (selectedLocation || 'Select Location')}</span>
      </button>
      {isOpen && (
        <div className="absolute mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-50">
          <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
            {locations.map((location) => (
              <button
                key={location}
                onClick={() => {
                  onLocationChange(location);
                  setIsOpen(false);
                }}
                className={`block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 w-full text-left ${
                  selectedLocation === location ? 'bg-gray-100 font-medium' : ''
                }`}
                role="menuitem"
              >
                {location}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default GeolocationFilter;