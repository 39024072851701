import React from 'react';

const SearchBar = ({ searchTerm, setSearchTerm, onSearch }) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    if (onSearch) {
      onSearch(searchTerm);
    } else {
      console.warn('onSearch prop is not provided to SearchBar component');
    }
  };

  return (
    <form onSubmit={handleSubmit} className="w-full max-w-2xl mx-auto">
      <div className="relative">
        <input
          type="text"
          placeholder="Search for sellers, products, or categories..."
          className="w-full px-4 py-3 rounded-full border-2 border-blue-200 focus:outline-none focus:border-blue-500 focus:ring-2 focus:ring-blue-200 shadow-lg text-lg transition duration-300"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <button
          type="submit"
          className="absolute right-3 top-1/2 transform -translate-y-1/2 bg-gradient-to-r from-indigo-500 to-blue-600 text-white p-2 rounded-full transition duration-300 hover:shadow-lg hover:opacity-90"
        >
          Search
        </button>
      </div>
    </form>
  );
};

export default SearchBar;