import React from 'react';
import { LockClosedIcon, DocumentTextIcon, CursorArrowRaysIcon } from '@heroicons/react/24/outline';

const Privacy = () => {
  const sections = [
    {
      icon: LockClosedIcon,
      title: "1. Information We Collect",
      content: "We collect information you provide directly to us, such as when you create an account, update your profile, or communicate with us. We may also collect information about your use of our services."
    },
    {
      icon: DocumentTextIcon,
      title: "2. How We Use Your Information",
      content: "We use the information we collect to provide, maintain, and improve our services, to communicate with you, and to personalize your experience on Stall.me."
    },
    {
      icon: CursorArrowRaysIcon,
      title: "3. Information Sharing and Disclosure",
      content: "We may share your information with third-party service providers who perform services on our behalf, such as hosting, data analysis, and customer service."
    },
  ];

  return (
    <div className="bg-gradient-to-b from-indigo-50 to-white min-h-screen">
      <div className="container mx-auto px-4 pt-24 md:pt-32 pb-16">
        <h1 className="text-4xl md:text-5xl font-bold text-center mb-8 text-indigo-800">Privacy Policy</h1>
        
        <div className="max-w-3xl mx-auto">
          <p className="text-lg text-gray-700 mb-8">
            At Stall.me, we take your privacy seriously. This Privacy Policy explains how we collect, use, and protect your personal information.
          </p>

          {sections.map((section, index) => (
            <div key={index} className="mb-8">
              <div className="flex items-center mb-4">
                <section.icon className="w-8 h-8 text-indigo-600 mr-4" />
                <h2 className="text-2xl font-semibold text-indigo-800">{section.title}</h2>
              </div>
              <p className="text-gray-700 ml-12">{section.content}</p>
            </div>
          ))}

          <p className="text-gray-700 mt-8">
            For the full Privacy Policy, please contact us at{' '}
            <a href="mailto:privacy@stall.me" className="text-blue-600 hover:underline">privacy@stall.me</a>.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Privacy;